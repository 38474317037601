@import url('https://fonts.googleapis.com/css?family=Bitter|Montserrat:300,400,800&display=swap');

h1, h2 {
  font-family: 'Bitter', serif;
}

body {
  color: #373737;
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

a {
  color: #999;
  text-decoration: none;
  transition: color 0.2s;

  &:hover {
    color: #111;
  }
}

.appContainer {
  margin: 0 auto;
  max-width: 978px;
}

/* Small devices and below (tablet, 768px and up) */
@media (max-width: 576px) {
  .appContainer {
    width: 100%;
  }
}
