.navbar {
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
}

.header {
  margin-bottom: 0;
  text-transform: uppercase;
}

.subheader {
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: 1.5px;
  margin-top: 5px;
  text-transform: uppercase;
}

.list {
  display: flex;
  list-style-type: none;
}

.link {
  color: #999;
  font-size: 12px;
  letter-spacing: 0.8px;
  list-style: none;
  padding: 15px;
  text-decoration: none;
  text-transform: uppercase;
}

.active {
  color: #111;
}

/* Small devices and below (tablet, 768px and up) */
@media (max-width: 576px) {
  .navbar {
    flex-direction: column;
  }

  .heading {
    padding: 0 20px;
    text-align: center;
  }

  .list {
    margin: 0 auto;
    padding: 0;
  }
}
