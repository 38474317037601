@import url(https://fonts.googleapis.com/css?family=Bitter|Montserrat:300,400,800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.styles_about__1lJ2H {
  align-items: center;
  display: flex;
  margin: 50px 100px; }

.styles_img__1VEN9 {
  height: 400px; }

.styles_description__2wRic {
  color: #373737;
  font-size: 14px;
  line-height: 2;
  padding: 0 60px; }

/* Small devices and below (tablet, 768px and up) */
@media (max-width: 576px) {
  .styles_about__1lJ2H {
    flex-direction: column;
    margin: 20px; }
  .styles_description__2wRic {
    padding: 20px 0; } }

.styles_contact__g4YGp {
  margin: 100px 100px;
  text-align: center; }

.styles_email__1_et6 {
  font-size: 25px;
  font-weight: 300;
  padding-bottom: 20px; }

.styles_logo__2Jo1t {
  height: 40px; }

/* Small devices and below (tablet, 768px and up) */
@media (max-width: 576px) {
  .styles_contact__g4YGp {
    margin: 20px auto; } }

.styles_resume__PISSq {
  margin: 20px 100px; }

.styles_section__19hjx {
  display: flex;
  font-size: 14px;
  padding-top: 40px; }

.styles_sectionTitle__3ppzy {
  color: #447b85;
  font-weight: 800;
  padding-right: 20px;
  text-align: right;
  width: 120px; }

.styles_entries__1ClaJ {
  flex: 1 0 auto; }

.styles_entry__2fx62 {
  display: flex;
  justify-content: space-between;
  padding-top: 20px; }
  .styles_entry__2fx62:first-child {
    margin-top: -20px; }

.styles_subtitle__2GMHx {
  color: #8c8c8c;
  padding-top: 10px; }

.styles_date__2JT2T {
  color: #447b85; }

.styles_skills__HX-KR {
  margin-top: -20px; }

/* Small devices and below (tablet, 768px and up) */
@media (max-width: 576px) {
  .styles_resume__PISSq {
    margin: 0 auto; }
  .styles_section__19hjx {
    flex-direction: column;
    padding: 20px; }
  .styles_sectionTitle__3ppzy {
    font-size: 20px;
    padding-bottom: 15px;
    text-align: left;
    text-transform: uppercase;
    width: auto;
    width: initial; }
  .styles_entry__2fx62 {
    flex-direction: column;
    padding-top: 15px; }
  .styles_subtitle__2GMHx {
    padding-top: 5px; }
  .styles_date__2JT2T {
    padding-top: 10px; } }

.styles_story__3Qp3p {
  margin: 50px 100px; }

.styles_title__2zv7_ {
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 1.5px;
  text-transform: uppercase; }

.styles_description__1kkF8 {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  padding-bottom: 10px; }

.styles_embed__7nVe0 {
  border: none;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%; }

.styles_iframeWrapper__3Jvdz {
  height: 0;
  padding-bottom: 64.8%;
  position: relative;
  width: 100%; }
  .styles_iframeWrapper__3Jvdz .styles_embed__7nVe0 {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }

/* Small devices and below (tablet, 768px and up) */
@media (max-width: 576px) {
  .styles_story__3Qp3p {
    margin: 20px; }
  .styles_embed__7nVe0 {
    height: 300px; } }

.styles_navbar__2eF_q {
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between; }

.styles_header__3oMPk {
  margin-bottom: 0;
  text-transform: uppercase; }

.styles_subheader__2Swij {
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: 1.5px;
  margin-top: 5px;
  text-transform: uppercase; }

.styles_list__30Dv5 {
  display: flex;
  list-style-type: none; }

.styles_link__1ED-N {
  color: #999;
  font-size: 12px;
  letter-spacing: 0.8px;
  list-style: none;
  padding: 15px;
  text-decoration: none;
  text-transform: uppercase; }

.styles_active__3InPd {
  color: #111; }

/* Small devices and below (tablet, 768px and up) */
@media (max-width: 576px) {
  .styles_navbar__2eF_q {
    flex-direction: column; }
  .styles_heading__1TEYv {
    padding: 0 20px;
    text-align: center; }
  .styles_list__30Dv5 {
    margin: 0 auto;
    padding: 0; } }

h1, h2 {
  font-family: 'Bitter', serif; }

body {
  color: #373737;
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

a {
  color: #999;
  text-decoration: none;
  -webkit-transition: color 0.2s;
  transition: color 0.2s; }
  a:hover {
    color: #111; }

.appContainer {
  margin: 0 auto;
  max-width: 978px; }

/* Small devices and below (tablet, 768px and up) */
@media (max-width: 576px) {
  .appContainer {
    width: 100%; } }

